import React, { Component } from 'react';
import Layout from "../layouts";
import background_image from '../images/pexels-coffee-contact-email-hands-4831-compressor.jpg';
import {Row, Col, Container} from "reactstrap";

class Kontakt extends Component {
  render() {
    if (typeof document !== `undefined`) {
      document.documentElement.classList.remove('nav-open');
    }
    return (
        <Layout theme="light" location={this.props.location} title="Antje Abel Architekten | Architekturbüro aus Karlsruhe" description="Wir sind eine Architekturbüro mit Sitz in Karlsruhe und können alles was mit Web zu tun hat: Strategie & Beratung, UI/UX-Design, Webapps & Websites, E-Commerce & Shops." keywords="Architektur, Karlsruhe, " robots="index, follow">
          <div className="section image-header" style={{backgroundImage: `url(${background_image})`}}>
              <div className="image-header-overlay" />
              <h1>Kontakt</h1>
          </div>
          <div className="section contact-section">
            <Container>
              <Row>
                <Col lg="6">
                  <div className="google_maps"></div>
                </Col>
                <Col lg="6" className="address">
                  <h3>Schreiben Sie uns eine E-Mail</h3>
                  <p><a className="link" href="mailto: info@antje-abel-architekten.de">info@antje-abel-architekten.de</a></p>
                  <h3>Rufen Sie uns an</h3>
                  <p><a className="link" href="tel: +49 (0) 171 5227974">+49 (0) 171 5227974</a></p>
                  <h3>Schicken Sie uns ein Fax</h3>
                  <p><a className="link" href="fax: +49 (0) 721 9430700">+49 (0) 721 94307020</a></p>
                  <h3>Folgen Sie uns</h3>
                  <p><a className="link" href="https://facebook.com" rel="noreferrer" target="_blank">Facebook</a> | <a className="link" href="https://houzz.com" rel="noreferrer" target="_blank">Houzz</a> | <a className="link" href="https://instagram.com" rel="noreferrer" target="_blank">Instagram</a></p>
                </Col>
              </Row>
            </Container>
          </div>
        </Layout>
    );
  }
}

export default Kontakt;
